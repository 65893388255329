<template>
  <div class="config">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="config_setting">
      <div class="config_d">
        汇率设置
        <div class="config_cple">
          <div>
            <p>CPLE&nbsp;</p>
            <div>当前汇率：$ {{ cpleRate }}</div>
          </div>
          <!-- <el-button type="primary" @click="setRate(1)">修改汇率</el-button> -->
        </div>
        <div class="config_usdt">
          <div>
            <p>CNY&nbsp;</p>
            <div>当前汇率：$ {{ usdtRate }}</div>
          </div>
          <el-button type="primary" @click="setRate">修改汇率</el-button>
        </div>
      </div>

      <div class="config_d">
        流量续费价格设置
        <div class="config_cple">
          <div>
            <p>年卡&nbsp;</p>
            <div>当前价格：$ {{ renewPrice }}</div>
          </div>
          <el-button type="primary" @click="setPrice">修改价格</el-button>
        </div>
        <div class="config_cple">
          <div>
            <p>月卡&nbsp;</p>
            <div>当前价格：$ {{ monthPrice }}</div>
          </div>
          <el-button type="primary" @click="setMonthPrice">修改价格</el-button>
        </div>
      </div>

      <div class="config_d" style="margin-top: 20px">
        模糊打点直线距离设置
        <div class="config_cple">
          <div>
            <p>模糊打点直线距离&nbsp;</p>
            <div>当前距离：{{ distance }}</div>
          </div>
          <el-button type="primary" @click="setDistance">修改</el-button>
        </div>
      </div>

      <div class="config_d" style="margin-top: 20px">
        火财盒奖励自动发放额度
        <div class="config_cple">
          <div>
            <p>自动发放额度&nbsp;</p>
            <div>当前额度：{{ limit }}</div>
          </div>
          <el-button type="primary" @click="setLimit">修改</el-button>
        </div>
      </div>

      <div class="config_d" style="margin-top: 20px">
        用户提现免审额度
        <div class="config_cple">
          <div>
            <p>自动提现额度&nbsp;</p>
            <div>当前额度：{{ withdraw }}</div>
          </div>
          <el-button type="primary" @click="setWithdraw">修改</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

export default {
  components: { Breadcrumb },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "配置管理",
        },
        {
          name: "系统参数设置",
        },
      ], // 面包屑数据

      cpleRate: "",
      usdtRate: "",

      renewPrice: "",
      monthPrice: "",

      distance: "",

      limit: "",

      withdraw: "",
    };
  },

  mounted() {
    showLoading();
    const optCple = {
      url: reqUrl.getCpleRate,
      method: "POST",
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.cpleRate = res.data.data;
        } else if (res.data.code == 999) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optCple);

    const optUsdt = {
      url: reqUrl.getUsdtRate,
      method: "POST",
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.usdtRate = res.data.data;
        } else if (res.data.code == 999) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optUsdt);

    const optPrice = {
      url: reqUrl.getOldBoxContinuationPrice,
      method: "POST",
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.renewPrice = res.data.data;
        } else if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optPrice);

    const optMonthPrice = {
      url: reqUrl.getBoxMonthContinuationPrice,
      method: "POST",
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.monthPrice = res.data.data;
        } else if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optMonthPrice);

    const optDistance = {
      url: reqUrl.getDistanceRate,
      method: "POST",
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.distance = res.data.data;
        } else if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optDistance);

    const optLimit = {
      url: reqUrl.getMatchBoxAutoCashOutLimit,
      method: "POST",
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.limit = res.data.data;
        } else if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optLimit);

    const optWithdrawLimit = {
      url: reqUrl.getAutoCashOutLimit,
      method: "POST",
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.withdraw = res.data.data;
        } else if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optWithdrawLimit);
  },

  methods: {
    setRate() {
      this.$prompt("请输入usdt汇率", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          showLoading();
          const optCple = {
            url: reqUrl.setUsdtRate,
            method: "POST",
            params: JSON.stringify({
              rate: value,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });

                this.refresh();
              } else if (res.data.code == 999) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(optCple);
        })
        .catch(() => {});
    },

    setPrice() {
      this.$prompt("请输入流量年卡续费价格", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          showLoading();
          const optCple = {
            url: reqUrl.setOldBoxContinuationPrice,
            method: "POST",
            params: JSON.stringify({
              price: value,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.refresh();
              } else if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(optCple);
        })
        .catch(() => {});
    },

    setMonthPrice() {
      this.$prompt("请输入流量月卡续费价格", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          showLoading();
          const optCple = {
            url: reqUrl.setBoxMonthContinuationPrice,
            method: "POST",
            params: JSON.stringify({
              price: value,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.refresh();
              } else if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(optCple);
        })
        .catch(() => {});
    },

    setDistance() {
      this.$prompt("请输入模糊打点直线距离", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          showLoading();
          const optCple = {
            url: reqUrl.setDistanceRate,
            method: "POST",
            params: JSON.stringify({
              rate: value,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.refresh();
              } else if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(optCple);
        })
        .catch(() => {});
    },

    setLimit() {
      this.$prompt("请输入火财盒奖励自动发放额度(CPLE)", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          showLoading();
          const optCple = {
            url: reqUrl.setMatchBoxAutoCashOutLimit,
            method: "POST",
            params: JSON.stringify({
              limit: value,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.refresh();
              } else if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(optCple);
        })
        .catch(() => {});
    },

    setWithdraw() {
      this.$prompt("请输入用户提现免审额度(USDT)", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          showLoading();
          const optCple = {
            url: reqUrl.setAutoCashOutLimit,
            method: "POST",
            params: JSON.stringify({
              limit: value,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.refresh();
              } else if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(optCple);
        })
        .catch(() => {});
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.config {
  position: relative;
  .config_setting {
    display: flex;
    flex-wrap: wrap;
  }

  .config_d {
    width: 50%;
    margin-top: 10px;

    .config_cple,
    .config_usdt {
      width: 80%;
      height: 100px;
      margin-top: 10px;
      background: #fff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        color: #f56c6c;
        font-weight: bold;
        margin-left: 20px;
      }

      div {
        display: flex;
        align-items: center;
      }

      .el-button {
        margin-right: 30px;
      }
    }
  }
}
</style>
